<template>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_187_7871)">
            <path
                d="M2.0769 8.3846C2.0769 8.3846 4.23075 4.0769 7.99998 4.0769C11.7692 4.0769 13.9231 8.3846 13.9231 8.3846C13.9231 8.3846 11.7692 12.6923 7.99998 12.6923C4.23075 12.6923 2.0769 8.3846 2.0769 8.3846Z"
                :stroke="strokeColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.99991 10.0001C8.89206 10.0001 9.61529 9.27682 9.61529 8.38467C9.61529 7.49252 8.89206 6.76929 7.99991 6.76929C7.10775 6.76929 6.38452 7.49252 6.38452 8.38467C6.38452 9.27682 7.10775 10.0001 7.99991 10.0001Z"
                :fill="strokeColor"
                :stroke="strokeColor"
                stroke-width="1.14286"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_187_7871">
                <rect
                    width="14"
                    height="10.7692"
                    fill="white"
                    transform="translate(1 3)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>

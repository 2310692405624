<template
    ><div class="selected-container">
        <button
            v-if="filterList.length"
            :disabled="isDisableButton.isDisablePrev"
            @click.prevent="prevButton"
            class="prev-filter"
        >
            <PrevChevIcon
                :strokeColor="
                    isDisableButton.isDisablePrev ? '#d1d1d1' : '#929292'
                "
            />
        </button>
        <div id="filters-container">
            <IndvFilter
                v-for="(objectValue, index) in filterList"
                :key="index"
                :filterName="objectValue.value"
                :category="objectValue.category"
                :clickedMethod="clickedMethod"
                :isFetching="isFetching"
                :styleString="`height:${indvFilterHeight}px;`"
            >
            </IndvFilter>
        </div>

        <button v-if="filterList.length" @click.prevent="nextButton" class="next-filter">
            <NextChevIcon />
        </button>
    </div>
</template>

<script>
import IndvFilter from "./IndvFilter.vue";
import PrevChevIcon from "../_icons/PrevChevIcon.vue";
import NextChevIcon from "../_icons/NextChevIcon.vue";
export default {
    name: "FilterItems",
    components: {
        IndvFilter,
        PrevChevIcon,
        NextChevIcon
    },
    props: {
        filterList: {
            type: Array
        },
        clickedMethod: {
            type: Function
        },
        isFetching: {
            type: Boolean
        },
        indvFilterHeight: {
            type: Number,
            default: 40
        }
    },
    data() {
        return {
            isDisableButton: {
                isDisablePrev: true,
                isDisableNext: false,
                scrollNum: 150
            }
        };
    },
    methods: {
        nextButton() {
            const container = document.querySelector("#filters-container");

            container.scrollLeft += this.isDisableButton.scrollNum;
            this.isDisableButton.isDisablePrev = false;
        },
        prevButton() {
            const container = document.querySelector("#filters-container");

            if (container.scrollLeft > 0) {
                container.scrollLeft -= this.isDisableButton.scrollNum;
                if (
                    container.scrollLeft - this.isDisableButton.scrollNum <=
                    0
                ) {
                    this.isDisableButton.isDisablePrev = true;
                }
            } else {
                this.isDisableButton.isDisablePrev = true;
            }
        }
    }
};
</script>

<style></style>

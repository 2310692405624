import Vue from "vue";

export default new Vue({
    data() {
        return {
            id: 0,
            modalEnum: {
                open: "open",
                close: "close"
            }
        };
    },
    methods: {
        open(component, props = {}) {
            let idComponent = this.id++;
            return {
                promise: new Promise((resolve, reject) => {
                    this.$emit(this.modalEnum.open, {
                        component,
                        props,
                        resolve,
                        reject,
                        idComponent
                    });
                }),
                close: () => {
                    this.$emit(this.modalEnum.close, { idComponent });
                }
            };
        }
    }
});

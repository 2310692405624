<template>
    <div>
        <input
            type="text"
            :class="classControl"
            :placeholder="placeholder"
            :name="name"
            v-validate="validation"
            v-model="selectedValue"
            :data-vv-as="validationName"
            :data-vv-scope="scope"
        />
        <div v-show="errors.first(errorField)" class="invalid-feedback">{{ errors.first(errorField) }}</div>
    </div>
</template>

<script>
    export default {
        inject: ["$validator"],
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: [Array, Number, String, Object],
            validation:{
                type: [String, Object],
                default:''
            },
            validationName:{
                type: String,
                default: 'Input ',
            },
            name:{
                type:String,
                default: 'input'
            },
            scope: {
                type: String,
                default:null
            },
            placeholder: {
                type: String,
                default:'Silahkan Masukan Data'
            },
            inputClass:{
                type: String,
                default: 'form-control'
            },
            errorClass:{
                type:String,
                default: 'form-control is-invalid'
            },
            customClass: [String, Array, Function, Object]
        },
        computed: {
            selectedValue: {
                get () {
                    return this.value
                },
                set (newValue) {
                    this.$emit('change', newValue);
                }
            },
            errorField: function(){
                 return (this.scope != null) ? `${this.scope}.${this.name}` : this.name
            },
            classControl: function(){
                if(this.customClass != undefined || this.customClass != null){
                    return this.customClass
                } else {
                    return this.errors.has(this.errorField) ? this.errorClass : this.inputClass;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>

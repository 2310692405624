<template>
    <svg
        width="24"
        height="26"
        viewBox="0 0 24 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1242_45284)">
            <path
                d="M20.4714 4.50903C19.2129 3.23914 17.6851 2.26816 16.001 1.66782C14.3169 1.06747 12.5195 0.853085 10.7415 1.04049C5.23084 1.59606 0.696207 6.07063 0.0805781 11.5813C-0.745265 18.8637 4.88548 25.005 11.9877 25.005C14.2552 25.005 16.4761 24.3616 18.3925 23.1496C20.3089 21.9376 21.842 20.2067 22.8138 18.158C23.2943 17.1519 22.5735 15.9958 21.4624 15.9958C20.9068 15.9958 20.3813 16.2961 20.1411 16.7916C19.2693 18.667 17.7782 20.185 15.9186 21.0902C14.0591 21.9954 11.9445 22.2325 9.93064 21.7617C6.59723 21.0259 3.90949 18.3081 3.20377 14.9747C2.90711 13.6568 2.91055 12.289 3.21382 10.9725C3.51709 9.65608 4.11244 8.42466 4.95583 7.36934C5.79922 6.31402 6.86908 5.4618 8.08626 4.87574C9.30344 4.28967 10.6368 3.98474 11.9877 3.9835C14.4803 3.9835 16.7025 5.01956 18.3242 6.65623L16.0569 8.92354C15.1109 9.86951 15.7716 11.4912 17.108 11.4912H22.4985C23.3243 11.4912 24 10.8155 24 9.98963V4.59913C24 3.26276 22.3783 2.58707 21.4324 3.53304L20.4714 4.50903V4.50903Z"
                :fill="strokeColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1242_45284">
                <rect width="24" height="26" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>

<style></style>

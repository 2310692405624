<template> </template>

<script>
import DangerImg from "../_icons/DangerImg.png";
import SuccessImg from "../_icons/SuccessImg.png";
import WarningImg from "../_icons/WarningImg.png";
import ErrorImg from "../_icons/ErrorImg.png";
export default {
    data() {
        return {
            dangerImg: DangerImg,
            warningImg: WarningImg,
            successImg: SuccessImg,
            errorImg: ErrorImg
        };
    },
    methods: {
        async show(swalOptions, icon ) {
            this.$mount();
            const image = this[icon] ? this[icon] : null;
            let classStr
            if (icon == "warningImg") {
                classStr = "container-swal-custom__success"
            }
            const result = await swal({
                title: swalOptions.title,
                html: swalOptions.html,
                text: swalOptions.text,
                timer: swalOptions.timer,
                imageUrl: image,
                customContainerClass: classStr ? classStr : "container-swal-custom",
                allowOutsideClick: swalOptions.allowOutsideClick
                    ? swalOptions.allowOutsideClick
                    : false,
                reverseButtons: swalOptions.reverseButtons
                    ? swalOptions.reverseButtons
                    : false,
                showCancelButton: swalOptions.showCancelButton,
                confirmButtonText: swalOptions.textConfirm
                    ? swalOptions.textConfirm
                    : "OK",
                cancelButtonText: swalOptions.textCancel
                    ? swalOptions.textCancel
                    : "Cancel"
            });
            return result;
        }
    }
};
</script>

<style></style>

<template>
    <div
        :style="
            !isPublished
                ? 'background: #FFF2F2;max-width: 150px;'
                : 'max-width: 150px;'
        "
        class="publish-chips"
    >
        <div style="width:16px;margin: 2px 8px 0 0;">
            <EyeFillIcon v-if="isPublished" />
            <EyeIconSlashed v-else />
        </div>
        <p>
            {{ textString }}
        </p>
    </div>
</template>

<script>
import EyeFillIcon from "../_icons/EyeFillIcon.vue";
import EyeIconSlashed from "../_icons/EyeIconSlashed.vue";
export default {
    name: "PublishedChip",
    components: {
        EyeFillIcon,
        EyeIconSlashed
    },
    props: {
        isPublished: {
            type: Boolean,
            default: true
        },
        textString: {
            type: String,
            default: "Missing textString"
        }
    }
};
</script>

<style></style>

<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 0C3.576 0 0 3.576 0 8C0 12.424 3.576 16 8 16C12.424 16 16 12.424 16 8C16 3.576 12.424 0 8 0ZM11.44 11.44C11.366 11.5142 11.2781 11.573 11.1813 11.6131C11.0845 11.6533 10.9808 11.674 10.876 11.674C10.7712 11.674 10.6675 11.6533 10.5707 11.6131C10.4739 11.573 10.386 11.5142 10.312 11.44L8 9.128L5.688 11.44C5.53842 11.5896 5.33554 11.6736 5.124 11.6736C4.91246 11.6736 4.70958 11.5896 4.56 11.44C4.41042 11.2904 4.32638 11.0875 4.32638 10.876C4.32638 10.7713 4.34701 10.6675 4.3871 10.5708C4.42718 10.474 4.48593 10.3861 4.56 10.312L6.872 8L4.56 5.688C4.41042 5.53842 4.32638 5.33554 4.32638 5.124C4.32638 4.91246 4.41042 4.70958 4.56 4.56C4.70958 4.41042 4.91246 4.32638 5.124 4.32638C5.33554 4.32638 5.53842 4.41042 5.688 4.56L8 6.872L10.312 4.56C10.3861 4.48593 10.474 4.42718 10.5708 4.3871C10.6675 4.34701 10.7713 4.32638 10.876 4.32638C10.9807 4.32638 11.0845 4.34701 11.1812 4.3871C11.278 4.42718 11.3659 4.48593 11.44 4.56C11.5141 4.63407 11.5728 4.72199 11.6129 4.81877C11.653 4.91554 11.6736 5.01926 11.6736 5.124C11.6736 5.22874 11.653 5.33246 11.6129 5.42923C11.5728 5.52601 11.5141 5.61393 11.44 5.688L9.128 8L11.44 10.312C11.744 10.616 11.744 11.128 11.44 11.44Z"
            fill="#929292"
        />
    </svg>
</template>

<script>
export default {};
</script>

<style></style>

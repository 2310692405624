<template>
    <form @submit.prevent="searchSubmit" class="search-doctor">
        <input
            style="padding-left: 20px;padding-right: 15px;outline-color: #d5d5d5;"
            type="text"
            placeholder="Cari id/dokter/spesialisasi/klinik"
            v-model="search"
        />
        <button type="submit" style="background: white;">
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.79199 16.583C13.0346 16.583 16.474 13.1436 16.474 8.90099C16.474 4.65834 13.0346 1.21899 8.79199 1.21899C4.54933 1.21899 1.10999 4.65834 1.10999 8.90099C1.10999 13.1436 4.54933 16.583 8.79199 16.583Z"
                    stroke="#23415B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M18.891 19L14.594 14.703"
                    stroke="#23415B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </button>
    </form>
</template>

<script>
export default {
    name: "SearchInput",
    data() {
        // var vx = this;
        return {
            search: null,
            searchDebounce: _.debounce(query => {
                // if (query) {
                this.$emit("search-submit", query);
                // }
            }, 500)
        };
    },
    watch: {
        search: {
            handler(val, oldVal) {
                //
                this.searchDebounce(val);
            }
        },
        isRenderComponent: {
            handler(val, oldVal) {
                this.search = null;
            }
        }
    },
    props: {
        isRenderComponent: {
            type: Boolean,
            default: true
        },
        initialSearch: {
            type: String
        }
    },
    methods: {
        searchSubmit() {
            // console.log("searchSubmit");
            this.$emit("search-submit", this.search);
        }
    },
    created() {
        if (this.initialSearch) {
            this.search = this.initialSearch;
        }
    }
};
</script>

<style></style>

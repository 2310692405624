<template>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_187_7870)">
            <path
                d="M9.14152 9.60305C8.99363 9.76176 8.81529 9.88906 8.61714 9.97735C8.41899 10.0656 8.20508 10.1131 7.98818 10.1169C7.77129 10.1208 7.55584 10.0809 7.35469 9.99963C7.15355 9.91838 6.97083 9.79745 6.81744 9.64406C6.66404 9.49066 6.54312 9.30795 6.46187 9.1068C6.38063 8.90566 6.34073 8.69021 6.34455 8.47331C6.34838 8.25641 6.39586 8.04251 6.48415 7.84436C6.57244 7.6462 6.69973 7.46786 6.85844 7.31998M11.1984 11.66C10.278 12.3616 9.15719 12.7503 7.99998 12.7692C4.23075 12.7692 2.0769 8.46151 2.0769 8.46151C2.74669 7.2133 3.67567 6.12276 4.80152 5.26305L11.1984 11.66ZM6.86921 4.28305C7.23985 4.1963 7.61932 4.15293 7.99998 4.15382C11.7692 4.15382 13.9231 8.46151 13.9231 8.46151C13.5962 9.073 13.2064 9.64868 12.76 10.1792L6.86921 4.28305Z"
                :stroke="strokeColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M2.0769 2.53845L13.9231 14.3846"
                :stroke="strokeColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_187_7870">
                <rect
                    width="14"
                    height="12.9231"
                    fill="white"
                    transform="translate(1 2)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>

<template>
    <button @click.prevent="buttonClicked" class="filter-doctor">
        <div v-show="activeFilter" class="active-icon">{{ activeFilter }}</div>
        <div class="text-icon">
            <FilterIcon />
            <p>Filter</p>
        </div>
    </button>
</template>

<script>
import FilterIcon from "../_icons/FilterIcon.vue";
export default {
    name: "FilterButton",
    components: {
        FilterIcon
    },
    props: {
        clickedMethod: {
            type: Function
        },
        activeFilter: {
            type: Number
        }
    },
    methods: {
        buttonClicked() {
            if (typeof this.clickedMethod == "function") {
                this.clickedMethod();
            }
        }
    }
};
</script>

<style></style>

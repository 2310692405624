export default {
    props: {
        dismiss: {
            type: Function,
            required: true
        },
        accept: {
            type: Function,
            required: true
        },
        idComponent: {
            type: [Number, String]
        },
        componentData: {
            type: Object
        }
    }
};

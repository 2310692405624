<template>
    <div class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        var body = document.body;
        body.classList.toggle("noscroll");
    },
    beforeDestroy() {
        var body = document.body;
        body.classList.toggle("noscroll");
    }
};
</script>

<template>
    <!-- v-b-tooltip.hover.left -->
    <div :title="filterName" class="filter-content" :style="styleString">
        <p>{{ filterName }}</p>
        <button :disabled="isFetching" @click.prevent="buttonClicked">
            <Xicon />
        </button>
    </div>
</template>

<script>
import Xicon from "../_icons/XIcon.vue";
export default {
    components: { Xicon },
    props: {
        filterName: {
            type: [String , Number],
            default: "No Name Filter"
        },
        clickedMethod: {
            type: Function
        },
        category: {
            type: [String , Number]
        },
        value: {
            type: [String, Object]
        },
        isFetching: {
            type: Boolean,
            default: false
        },
        styleString: {
            type: String,
            default: ''
        }
    },
    methods: {
        buttonClicked() {
            // this.$emit("closeFilter");
            if (typeof this.clickedMethod == "function") {
                this.clickedMethod({
                    category: this.category,
                    value: this.filterName
                });
            }
        }
    }
};
</script>

<style></style>
